import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'frame',
    component: () => import('@/views/frame/index.vue'),
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/HomeView.vue'),
  // },
  {
    // 订阅我们(自适应已完成)
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/subscription/index.vue'),
  },
  {
    // 新闻咨询(自适应已完成)
    path: '/message',
    name: 'message',
    component: () => import('@/views/message/index.vue'),
  },
  {
    // frame-33(自适应已完成)
    path: '/frame',
    name: 'frame',
    component: () => import('@/views/frame/index.vue'),
  },
  {
    // moonland(自适应已完成)
    path: '/moonland',
    name: 'moonland',
    component: () => import('@/views/moonland/index.vue'),
  },
  {
    // 关于我们(自适应已完成)
    path: '/ourselves',
    name: 'ourselves',
    component: () => import('@/views/ourselves/index.vue'),
  },
  {
    // mooncity(自适应已完成)
    path: '/mooncity',
    name: 'mooncity',
    component: () => import('@/views/mooncity/index.vue'),
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
      return { x: 0, y: 0 };
  }
})

export default router
