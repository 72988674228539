<template>
  <div id="app">
    <div class="title" v-show="$store.state.cut">
      <div class="titleImg">
        <img src="./assets/titleLog.png" style="width: 100%;height: 2.2578vw;margin-top: .6771vw;">
      </div>
      <div class="titleTab">
        <!-- <router-link to="/moonland" class="link">关于白月城</router-link> -->
        <!-- <el-dropdown :hide-on-click="true">
          <span class="el-dropdown-link" >
            关于白月城
          </span>
          <el-dropdown-menu slot="dropdown" >
            <div class="dropdownTxt" @click="asd" style="">MoonCity</div>
            <div class="dropdownTxt" @click="asd" style="">MoOnLand</div>
            <div class="dropdownTxt" @click="asd" style="">MoonVerse</div>
            <el-dropdown-item>MoonCity</el-dropdown-item>
            <el-dropdown-item>MoOnLand</el-dropdown-item>
            <el-dropdown-item>MoonVerse</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <div class="dropdownBox">
          <div class="txt" @mouseenter="qwe">关于白月城</div>
          <div class="txtbox" v-show="txtbox">
            <div class="txtbox_min" @click="asd('MoonCity')">MoonCity</div>
            <div class="txtbox_min" @click="asd('MoOnLand')">MoOnLand</div>
            <div class="txtbox_min" @click="asd('frame')">MoonVerse</div>
          </div>
        </div>
        <div @click="asd('message')" class="link" style="cursor: pointer;">新闻资讯</div>
        <div @click="asd('ourselves')" class="link" style="cursor: pointer;">关于我们</div>
        <div @click="asd('subscription')" class="link" style="cursor: pointer;">订阅我们</div>
        <!-- <router-link to="/message" class="link">新闻资讯</router-link>
        <router-link to="/ourselves" class="link">关于我们</router-link>
        <router-link to="/subscription" class="link">订阅我们</router-link> -->
      </div>
    </div>
    <div class="box">
      <router-view></router-view>
    </div>
    <div class="base" v-show="$store.state.cut">
      <div class="baseBox">
        <div class="baseBoxTop">
          <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_log"></div>
          </div>
          <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_bt">关于白月城</div>
            <div class="baseBoxTopMin_nr" @click="asd('MoonCity')">→ MoonCity</div>
            <div class="baseBoxTopMin_nr" @click="asd('MoOnLand')">→ 月陆MoOnLand</div>
            <div class="baseBoxTopMin_nr">→ MoonVerse</div>
          </div>
          <!-- <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_bt">新闻资讯</div>
            <div class="baseBoxTopMin_nr">→ MoonCity</div>
            <div class="baseBoxTopMin_nr">→ 月陆MoOnLand</div>
            <div class="baseBoxTopMin_nr">→ MoonVerse</div>
          </div> -->
          <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_bt" @click="asd('ourselves')">关于我们</div>
            <!-- <div class="baseBoxTopMin_nr">→ MoonCity</div>
            <div class="baseBoxTopMin_nr">→ 月陆MoOnLand</div>
            <div class="baseBoxTopMin_nr">→ MoonVerse</div> -->
          </div>
          <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_bt" @click="asd('subscription')">会员订阅</div>
            <!-- <div class="baseBoxTopMin_bt">用户协议</div>
            <div class="baseBoxTopMin_bt">隐私协议</div> -->
          </div>
          <div class="baseBoxTopMin">
            <div class="baseBoxTopMin_ewm"></div>
            <div class="baseBoxTopMin_wz">
              白月城MoonCity公众号
            </div>
          </div>
        </div>
        <div class="baseBoxXian"></div>
        <div class="baseBoxWzi">白洞文化科技（北京）有限公司版权所有 I Copyright© 2002-2029 All Right rescrverd.</div>
      </div>
    </div>
    <!-- <div class="mooncityEwm"></div> -->
  </div>
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue';
// import frontHome from './views/front/frontHome.vue';
export default {
  data() {
    return {
      txtbox: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log('路由变化了')
      console.log('当前页面路由：' + to.path);
      console.log('上一个路由：' + from);
      if(to.path !== '/') {
        this.$store.commit('asdwe', true);
      } else {
        this.$store.commit('asdwe', false);
      }
    },
  },
  methods: {
    qwe() {
      this.txtbox = true;
    },
    // 切换页面显示
    asd(scale) {
      this.txtbox = false;

      if (scale == 'MoonVerse') {

      } else if (scale == 'frame') {
        this.$store.commit('asdwe', false);
        this.$router.push('/');
      } else {
        if (this.$route.path !== '/' + scale) {
          this.$store.commit('asdwe', true);
          this.$router.push('/' + scale);
        // this.$router.replace('/' + scale);
        }
      }

    }
  }
};
</script>
<style lang="less">
.title {
  height: 3.6458vw;
  background-color: #fff;

  .titleImg {
    width: 7.8125vw;
    height: 3.6458vw;
    margin: 0 auto;
    display: flex;
  }

  .titleTab {
    height: 3.6458vw;
    position: absolute;
    // top: 1.1458vw;
    top: 0;
    right: 11.8229vw;
    display: flex;

    .link {
      color: #333333;
      font-size: .7292vw;
      font-weight: 500;
      // line-height: 1.0417vw;
      line-height: 3.6458vw;
      // display: inline-block;
      margin-right: 2.8125vw;
    }

    .link:hover {
      background-color: rgba(255, 255, 255, 0);
    }

    .link:nth-child(3) {
      margin-right: 2.2917vw;
    }

    .link:nth-child(4) {
      margin-right: 0px;
    }

    .dropdownBox {

      font-size: .7292vw;
      font-weight: 500;
      // line-height: 1.0417vw;
      line-height: 3.6458vw;
      margin-right: 2.8125vw;
      // display: inline-block;
      cursor: pointer;

      .txtbox {
        display: none;
      }
    }

    .dropdownBox:hover {
      // color: #333333;
      font-size: .7292vw;
      font-weight: 500;
      // line-height: 1.0417vw;
      line-height: 3.6458vw;
      display: inline-block;
      margin-right: 2.8125vw;
      display: inline-block;
      cursor: pointer;

      .txtbox {
        color: #B2B2B2;
        width: 6.25vw;
        height: 6.25vw;
        position: absolute;
        top: 3.4063vw;
        right: -1.3542vw;
        display: block;
        z-index: 999;
        .txtbox_min {
          width: 6.25vw;
          height: 2.0833vw;
          line-height: 2.0833vw;
          text-align: center;
          background-color: #fff;
          // z-index: 10;
        }

        .txtbox_min:hover {
          width: 6.25vw;
          height: 2.0833vw;
          line-height: 2.0833vw;
          text-align: center;
          background-color: #fff;
          z-index: 1;
          color: #000;
          background-color: #DDDDDD;
        }
      }

    }

    .dropdownBox::selection {
      .txtbox_min {
        display: none;
      }
    }
  }
}

.box {}


.base {
  width: 100%;
  height: 23.2813vw;
  background-color: #1F1F1F;
  padding-top: 4.5833vw;

  .baseBox {
    width: 70vw;
    height: 13.4896vw;
    margin: 0 auto;
    // width: 54.1667vw;
    // background-color: #fff;

    .baseBoxTop {
      height: 10.5729vw;
      display: flex;
      justify-content: space-between;

      .baseBoxTopMin {
        min-width: 6.7708vw;
      }

      .baseBoxTopMin:nth-child(1) {
        width: 7.0833vw;
        // margin-right: 3.2813vw;

        // height: 151px;
        .baseBoxTopMin_log {
          width: 7.0833vw;
          height: 2.049vw;
          background: url('./assets/baseBoxTopMin_log.png');
          // background: url('./assets/logDi.png');
          background-size: cover;
          background-repeat: round;
          position: absolute;
          top: -0.3125vw;
        }
      }

      .baseBoxTopMin:nth-child(2) {
        height: 7.8646vw;
        // margin-right: 3.5938vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #FFFFFF;

        .baseBoxTopMin_bt {
          font-weight: 500;
          font-size: .9375vw;
          color: #FFFFFF;
          line-height: 1.0938vw;
          // cursor: pointer;
        }

        .baseBoxTopMin_nr {
          font-weight: 400;
          font-size: .8333vw;
          color: #FFFFFF;
          line-height: .9896vw;
          cursor: pointer;
        }
      }

      // .baseBoxTopMin:nth-child(3) {
      //   height: 7.8646vw;
      //   margin-right: 3.5938vw;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   color: #FFFFFF;

      //   .baseBoxTopMin_bt {
      //     font-weight: 500;
      //     font-size: .9375vw;
      //     color: #FFFFFF;
      //     line-height: 1.0938vw;
      //   }

      //   .baseBoxTopMin_nr {
      //     font-weight: 400;
      //     font-size: .8333vw;
      //     color: #FFFFFF;
      //     line-height: .9896vw;
      //     cursor: pointer;
      //   }
      // }

      .baseBoxTopMin:nth-child(3) {
        height: 7.8646vw;
        // margin-right: 2.0833vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #FFFFFF;

        .baseBoxTopMin_bt {
          font-weight: 500;
          font-size: .9375vw;
          color: #FFFFFF;
          line-height: 1.0938vw;
          cursor: pointer;
        }

        .baseBoxTopMin_nr {
          font-weight: 400;
          font-size: .8333vw;
          color: #FFFFFF;
          line-height: .9896vw;
          cursor: pointer;
        }
      }

      .baseBoxTopMin:nth-child(4) {
        min-width: 3.9583vw;
        height: 5.7813vw;
        // margin-right: 3.3333vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #FFFFFF;

        .baseBoxTopMin_bt {
          font-weight: 500;
          font-size: .9375vw;
          color: #FFFFFF;
          line-height: 1.0938vw;
          cursor: pointer;
        }
      }

      .baseBoxTopMin:nth-child(5) {
        width: 7.3958vw;
        height: 5.7813vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #FFFFFF;

        .baseBoxTopMin_ewm {
          width: 4.5313vw;
          height: 4.5313vw;
          margin: 0 auto;
          margin-bottom: .5729vw;
          background: url('./assets/Ewm.png');
          background-size: cover;
          background-repeat: round;
        }

        .baseBoxTopMin_wz {
          font-weight: 400;
          font-size: .625vw;
          color: #FFFFFF;
          line-height: .8333vw;
          text-align: center;
        }
      }
    }

    .baseBoxXian {
      width: 100%;
      height: .0521vw;
      background-color: #626262;
      margin-bottom: 1.875vw;
    }

    .baseBoxWzi {
      font-weight: 500;
      font-size: .7292vw;
      color: #FFFFFF;
      line-height: .8333vw;
    }
  }
}
// .mooncityEwm {
//     width: 100%;
//     height: 47.8646vw;
//     background-color: rgba(0, 0, 0, 0.4);
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 20;
//   }
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }</style>
